import { useRef, useState } from 'react';
import { useRouter } from 'next/router';

import { autoCompleteApi } from 'api/autoCompleteApi';

import { useURLStateManagement } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';
import { useFiltersState } from 'features/filters/Filters.context';

import { PAGE } from 'helpers/pages';
import { rg4js } from 'helpers/raygun';
import { pushToDataLayer } from 'services/gtmService';
import { asString } from 'utils/query-parameters';
import { MAKE_MODEL_SEO_SUPPLEMENTARY_FILTER_TYPES } from 'features/filters/Filters.constants';
import {
  formatPathParameters,
  getFilterPathParameterData,
} from 'helpers/seo/searchParams';
import { mapMakeModelQuery } from 'components/SearchPage/features/makeModel/MakeModel.map';

import type { IHandleSelectSearchItem } from 'components/Toolkit/SearchModal/BaseSearch';
import type {
  IAutoCompleteSuggestion,
  TAutoCompleteSearchRequestMakeModelFilters,
} from 'types';

import type { KeywordSearchProps as KeywordSearchArgs } from 'components/SearchPage/components/KeywordSearch/KeywordSearch.typed';

import { media, matchMedia } from 'helpers/breakpoints';

const useKeywordSearch = (args: KeywordSearchArgs) => {
  const { sectionName } = args;

  const searchRef = useRef<HTMLDivElement>(null);
  const [autoSuggestItems, setAutoSuggestItems] =
    useState<Array<IAutoCompleteSuggestion>>();

  const { query } = useRouter();
  const { section, words, make } = query;
  const keywordQueryValue = asString(words) ?? '';
  const {
    currentSection,
    makePathParameter,
    modelPathParameter,
    SEOFilterValue,
  } = formatPathParameters(section);

  const { filtersData } = useFiltersState();

  const { updateURL, updateMakeModels } = useURLStateManagement();

  const onClickSuggestion = (args: IHandleSelectSearchItem) => {
    const { autoSuggestedText, searchRequest } = args;

    const searchRequestMakeAndModel = searchRequest?.makeModelFilters?.[0];

    if (searchRequestMakeAndModel) {
      return handleMakeModelSelection(
        searchRequestMakeAndModel,
        autoSuggestedText,
      );
    }

    if (searchRequest?.filters) {
      return handleFilterSelection(searchRequest?.filters, autoSuggestedText);
    }

    updateURL({ queryParams: { words: autoSuggestedText } });

    pushToDataLayer({
      event: 'keyword_search',
      searchType: 'section_keyword_search',
      searchTerm: autoSuggestedText,
    });
  };

  const handleMakeModelSelection = (
    searchRequestMakeAndModel: TAutoCompleteSearchRequestMakeModelFilters,
    autoSuggestedText: string,
  ) => {
    const searchRequestMake = searchRequestMakeAndModel.make;

    const [searchRequestModel] = searchRequestMakeAndModel?.model
      ? [searchRequestMakeAndModel.model]
      : [];

    if (makePathParameter) {
      handleMakePathParameter(searchRequestMake, searchRequestModel);
    } else if (make) {
      const makeValue = Array.isArray(make) ? make : [make];

      handleMakeQueryValue(makeValue, searchRequestMake, searchRequestModel);
    } else {
      const makeModels = [
        {
          make: searchRequestMake,
          model: searchRequestModel ? [searchRequestModel] : undefined,
        },
      ];

      updateMakeModels({
        makeModels,
        makeModelEditable: `make_model_${makeModels.length - 1}`,
      });
    }

    pushToDataLayer({
      event: 'keyword_search',
      searchType: 'section_structured_search',
      searchTerm: autoSuggestedText,
    });
  };

  const handleMakePathParameter = (
    searchRequestMake?: string,
    searchRequestModel?: string,
  ) => {
    if (makePathParameter === searchRequestMake) {
      const model = [
        ...(modelPathParameter &&
        searchRequestModel &&
        searchRequestModel !== modelPathParameter
          ? [modelPathParameter]
          : []),
        ...(searchRequestModel ? [searchRequestModel] : []),
      ];

      const makeModels = [
        {
          make: searchRequestMake,
          model: model.length > 0 ? model : undefined,
        },
      ];

      updateMakeModels({
        makeModels,
        makeModelEditable: `make_model_${makeModels.length - 1}`,
      });
    } else {
      const makeModels = [
        {
          make: makePathParameter,
          model: modelPathParameter ? [modelPathParameter] : undefined,
        },
        {
          make: searchRequestMake,
          model: searchRequestModel ? [searchRequestModel] : undefined,
        },
      ];

      updateMakeModels({
        makeModels,
        makeModelEditable: `make_model_${makeModels.length - 1}`,
      });
    }
  };

  const handleMakeQueryValue = (
    makeValues: Array<string>,
    searchRequestMake?: string,
    searchRequestModel?: string,
  ) => {
    const includesMake = makeValues.some(
      (make) => searchRequestMake && make.startsWith(searchRequestMake),
    );

    const mappedMakeModels = makeValues.flatMap((make) => {
      return (
        mapMakeModelQueries(make, searchRequestMake, searchRequestModel) ?? []
      );
    });

    const newMake = {
      make: searchRequestMake,
      model: searchRequestModel ? [searchRequestModel] : undefined,
    };

    const makeModels = [
      ...mappedMakeModels,
      // If the search suggestion does not match any preexisting makes we add it here
      ...(!includesMake && searchRequestMake ? [newMake] : []),
    ];

    updateMakeModels({
      makeModels,
      makeModelEditable: `make_model_${makeModels.length - 1}`,
    });
  };

  const mapMakeModelQueries = (
    make: string,
    searchRequestMake?: string,
    searchRequestModel?: string,
  ) => {
    // Make query does not match suggestion so we map query as normal
    if (searchRequestMake && !make.includes(searchRequestMake)) {
      return mapMakeModelQuery(make);
    }
    // Make query does match suggestion but the model does not, so we update model data associated with this query value
    const mappedItem = mapMakeModelQuery(make);

    const updateModels =
      searchRequestModel && !mappedItem.model?.includes(searchRequestModel);

    return [
      {
        ...mappedItem,
        model: [
          ...(searchRequestModel ? mappedItem.model : []),
          ...(updateModels ? [searchRequestModel] : []),
        ],
      },
    ];
  };

  const handleFilterSelection = (
    searchRequestFilters: {
      name: string;
      values: Array<string>;
    }[],
    autoSuggestedText: string,
  ) => {
    const [filter] = searchRequestFilters;
    const { name, values } = filter;
    const [value] = values;

    const { formattedFilterName } = getFilterPathParameterData(
      filtersData,
      SEOFilterValue,
    );

    const addFilterPathParameter =
      MAKE_MODEL_SEO_SUPPLEMENTARY_FILTER_TYPES.includes(name) &&
      makePathParameter &&
      modelPathParameter &&
      !SEOFilterValue;

    if (addFilterPathParameter) {
      updateURL({ pathParam: value, queryParams: { [name]: '' } });
    } else if (formattedFilterName === name && SEOFilterValue) {
      updateURL({
        pathParam: '',
        queryParams: {
          [name]: [SEOFilterValue, value],
        },
      });
    } else if (query[name]) {
      const currentValue = query[name] ?? [];
      const formattedCurrentValue = Array.isArray(currentValue)
        ? currentValue
        : [currentValue];

      updateURL({
        queryParams: {
          [name]: [value, ...formattedCurrentValue],
        },
      });
    } else {
      const queryParams = { [name]: value };
      updateURL({ queryParams });
    }
    pushToDataLayer({
      event: 'keyword_search',
      searchType: 'section_structured_search',
      searchTerm: autoSuggestedText,
    });
  };

  const handleSearchInput = async (text: string) => {
    const rightTextMuted = `In ${sectionName}`;
    if (text.length > 2) {
      try {
        const { data } = await autoCompleteApi.getAutoCompleteValues(
          text,
          currentSection,
        );
        const filteredData: IAutoCompleteSuggestion[] = [
          ...data.suggestions.map((suggestion) => {
            return {
              ...suggestion,
              rightTextMuted,
              leftText: suggestion.leftText ?? `${text}`,
            };
          }),
        ];

        // We manually add search term to start if not suggested
        if (
          filteredData[0]?.leftText?.toLowerCase() !== text.toLowerCase() &&
          currentSection
        ) {
          filteredData.unshift({
            url: '',
            leftText: text,
            rightText: '',
            rightTextMuted,
            searchRequest: {
              terms: text,
              sections: [currentSection],
            },
          });
        }

        /* We manually add global search for term to end of array
        if not in all sections */
        if (section !== 'all') {
          filteredData.push({
            url: `/all?words=${text}`,
            rightText: 'In All Sections',
            leftText: text,
            searchRequest: {
              terms: text,
              sections: ['all'],
            },
          });
        }

        setAutoSuggestItems(filteredData);
      } catch (error) {
        rg4js('send', {
          error: new Error('Error handling handleSearchInput'),
          tags: [PAGE.SEARCH],
          customData: {
            text: text,
            keywordQueryValue: keywordQueryValue,
            autoSuggestItems: autoSuggestItems,
            section,
            message: error.message || 'client_error',
          },
        });
      }
    } else {
      setAutoSuggestItems(undefined);
    }
  };

  const onSearch = (words: string) => {
    updateURL({
      queryParams: {
        words,
      },
    });
  };

  const onFocus = () => {
    if (matchMedia(media.smallOnly) && searchRef?.current) {
      searchRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const onClose = () => setAutoSuggestItems(undefined);

  return {
    onClickSuggestion,
    onSearch,
    keywordQueryValue,
    handleSearchInput,
    onFocus,
    searchRef,
    autoSuggestItems,
    onClose,
  };
};

export { useKeywordSearch };
